import React, { useState } from 'react';
import { Anchor, ColumnConfig, DataTable, Text } from 'grommet';
import { IEquipamentoDocumento } from '../../../../../Interfaces/IEquipamentoDocumento';
import { IDataTableSort } from '../../../../../Interfaces/IDataTableSort';
import { useNavigate } from 'react-router-dom';

export interface VencimentosDocumentosEquipamentosProps {
  documentos: IEquipamentoDocumento[];
}

const VencimentosDocumentosEquipamentos: React.FC<VencimentosDocumentosEquipamentosProps> = ({ documentos }): JSX.Element => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<IDataTableSort>({
    property: 'DiasParaVencimento',
    direction: 'desc',
  });

  const columns: ColumnConfig<IEquipamentoDocumento>[] = [
    {
      property: 'Guid',
      primary: false,
      search: true,
      sortable: true,
      header: 'Equipamento',
      render: (datum: IEquipamentoDocumento): JSX.Element => {
        const onEquipamentoClick = (): void => {
          navigate(`/admin/manageEquipamentosDocumentos/${datum.Equipamento?.Guid}`);
        };

        return (
          <Anchor
            title="Clique para ver detalhes"
            onClick={onEquipamentoClick}
            size="small"
          >
            {datum.Equipamento?.Nome}
          </Anchor>
        );
      },
    },
    {
      property: 'Nome',
      primary: false,
      search: true,
      sortable: true,
      header: 'Documento',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Descricao',
      primary: false,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Descricao}</Text>
      ),
    },
    {
      property: 'DataVencimento',
      primary: false,
      search: false,
      sortable: true,
      header: 'Data de Vencimento',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.DataVencimento}</Text>
      ),
    }, {
      property: 'DiasParaVencimento',
      primary: false,
      search: false,
      sortable: true,
      header: 'Dias até o vencimento',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.DiasParaVencimento || 0}</Text>
      ),
    },
    // {
    //   property: 'Guid',
    //   primary: false,
    //   search: false,
    //   sortable: false,
    //   header: 'Ações',
    //   render: (datum: IEquipamentoDocumento): React.ReactNode => (
    //     <>{datum.DiasParaVencimento}</>
    //     // <ManageEmpresasDocumentosActions
    //     //   documento={datum}
    //     //   refreshData={refreshData}
    //     // />
    //   ),
    // },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={documentos}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

VencimentosDocumentosEquipamentos.displayName = 'VencimentosDocumentosEquipamentos';

export default VencimentosDocumentosEquipamentos;
